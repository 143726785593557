import React, {Component} from "react";
import {connect} from "react-redux";

class NotFound extends Component {

    render() {
        return <div className="container">
            <div className="my-5 text-center">
                <h1 className="text-dark text-uppercase font-weight-bold">404 - Página no encontrada</h1>
                <p className="text-muted">Lo sentimos, no encontramos la URL que solicitaste.</p>
            </div>
            </div>;
    }
}

const mapStateToProps = ({ bookingInformationReducer }) => bookingInformationReducer;

export default connect(mapStateToProps)(NotFound);
