import React, {Component} from "react";
import {connect} from "react-redux";

class Unauthorized extends Component {

    render() {
        return <div className="container">
              <div className="my-5 text-center">
                <h1 className="text-dark text-uppercase font-weight-bold">Página no autorizada</h1>
                <p className="text-muted">Ha intentado acceder a una página para la que no está autorizado.</p>
              </div>
            </div>;
    }
}

const mapStateToProps = ({ bookingInformationReducer }) => bookingInformationReducer;

export default connect(mapStateToProps)(Unauthorized);
