import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Reissue from "./Checkout"
import Result from "./Result"
import withLayout from "../layout";
import "../app.css";
import moment from "moment-timezone";
import HomePage from "./Home/HomePage";
import NotFound from "./Errors/NotFound";
import Unauthorized from "./Errors/Unauthorized";

class App extends Component {
    componentDidMount() {
        moment.tz.setDefault("UTC");
    }

    render() {
    return (
        <div className="zReissue">
          <BrowserRouter basename="/">
            <div className="main">
            <Switch>
              <Route exact path="/" component={withLayout(Unauthorized)} />
              <Route exact path="/home" component={withLayout(Unauthorized)} />
              <Route exact path="/reissues/gds/:gds/pnr/:pnr/email/:email/signature/:signature/orderId/:orderId/accountId/:accountId" component={withLayout(HomePage)} />
              <Route exact path="/recommendations/:recommendationId" component={withLayout(Reissue)}/>
              <Route exact path="/emission" component={withLayout(Result)} />
              <Route component={NotFound} />
            </Switch>
            </div>
          </BrowserRouter>
        </div>
    );
  }
}

export default App;
